import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class EmployeeProvider extends HttpRequest {
  getEmployees (query) {
    this.setHeader(getAuthToken())
    return this.get('employee', query)
  }

  getEmployeeById (id) {
    this.setHeader(getAuthToken())
    return this.get(`employee/${id}`)
  }

  getEmployeeByUserId (id) {
    this.setHeader(getAuthToken())
    return this.get(`employee/${id}/user-id`)
  }

  getEmployeeByUserTel (tel) {
    this.setHeader(getAuthToken())
    return this.get(`employee/${tel}/user-tel`)
  }

  updateEmployee (employeeForm) {
    this.setHeader(getAuthToken())
    return this.put(`employee/${employeeForm.id}`, employeeForm)
  }

  deleteEmployee (id) {
    this.setHeader(getAuthToken())
    return this.delete(`employee/${id}`)
  }
}

export default EmployeeProvider
