<template>
  <v-container
    class="pms-broadcast-container"
    fluid>
    <employee-selector-modal
      :dialog="employeeSelectorModal"
      @input="recieveEmployees($event)"
      @close-modal="employeeSelectorModalClose()" />

    <h2>Daily Briefs Broadcast</h2>

    <section class="select-target">
      <h3>Select target</h3>
      <v-radio-group v-model="selected">
        <v-radio
          label="All (ส่งหาพนักงานทุกคนที่ Active)"
          value="all" />
        <v-radio
          label="Role"
          value="role" />
        <v-select
          v-model="selectedPermission"
          class="pms-broadcast-select mb-2"
          :items="permissions"
          item-text="text"
          item-value="value"
          label="Role"
          hide-details
          outlined
          dense
          :disabled="selected !== 'role'" />
        <v-radio
          label="Name"
          value="name" />
        <v-combobox
          v-model="employees"
          class="pms-broadcast-select mb-2"
          prepend-inner-icon="mdi-label"
          append-icon="mdi-account-box-outline"
          outlined
          dense
          deletable-chips
          hide-selected
          label="ผู้รับ"
          multiple
          hide-details
          persistent-hint
          small-chips
          :disabled="selected !== 'name'"
          @keypress="$keypressNumber($event)"
          @click:append="employeeSelectorModalOpen()" />
        <v-radio
          label="Morning Brief"
          value="morning_brief" />
      </v-radio-group>
    </section>

    <section class="pms-broadcast-message-section">
      <line-message-box v-model="lineMessages" />
      <v-btn
        class="send-message-button"
        color="default"
        @click="preparedToSendLine()">
        ส่งข้อความ
      </v-btn>
    </section>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import EmployeeRoles from '@/assets/json/EmployeeRoles.json'
import PMSBroadcastProvider from '@/resources/PMSBroadcastProvider'
import LineMessageBox from '../components/broadcast/LineMessageBox.vue'
import EmployeeSelectorModal from '../components/broadcast/EmployeeSelectorModal.vue'

const PMSBroadcastService = new PMSBroadcastProvider()

export default {
  components: {
    LineMessageBox,
    EmployeeSelectorModal
  },
  data () {
    return {
      permissions: [
        {
          text: 'Sales Staff',
          value: EmployeeRoles.SALES_STAFF
        },
        {
          text: 'Assist Store Manager',
          value: EmployeeRoles.ASSIST_STORE_MANAGER
        },
        {
          text: 'Store Manager',
          value: EmployeeRoles.STORE_MANAGER
        },
        {
          text: 'Area Manager',
          value: EmployeeRoles.AREA_MANAGER
        }
      ],
      selected: null,
      selectedPermission: null,
      lineMessages: [],
      employees: [],
      employeeSelected: null,
      employeeSelectorModal: false
    }
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setLoading: 'Components/setLoading'
    }),
    employeeSelectorModalOpen () {
      this.employeeSelectorModal = true
    },
    employeeSelectorModalClose () {
      this.employeeSelectorModal = false
    },
    recieveEmployees (employees) {
      this.employeeSelected = employees
      this.employees = this.getEmployeesName(employees)

      this.employeeSelectorModalClose()
    },
    getEmployeesName (employees) {
      return employees.map((employee) => `${employee.firstName} ${employee.lastName}`)
    },
    async preparedToSendLine () {
      if (!this.lineMessages.length) {
        return
      }

      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'GETTING EMPLOYEES...'
        })

        const payload = {
          groups: [],
          lineUids: [],
          messages: []
        }
        let employees = []

        if (this.selected === 'name') {
          employees = this.employeeSelected

          employees.forEach((employee) => {
            const lineUid = employee?.lineUid || null
            if (lineUid) {
              payload.lineUids.push(lineUid)
            }
          })
        } else if (this.selected === 'role') {
          payload.groups.push(this.selectedPermission)
        }

        payload.messages = this.lineMessages.map((each) => {
          let temp = each
          if (each.type === 'text') {
            temp = {
              type: 'text',
              text: each.text
            }
          } else if (each.type === 'image') {
            temp = {
              type: 'image',
              originalContentUrl: each.originalContentUrl,
              previewImageUrl: each.originalContentUrl
            }
          }

          return temp
        })

        if (payload.messages.length > 0) {
          await this.sendLineMessages(payload)
        }
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[GETTING EMPLOYEES ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    },
    async sendLineMessages (payload) {
      try {
        this.setLoading({
          active: true,
          clickAble: false,
          message: 'SENDING LINE MESSAGES...'
        })

        if (this.selected === 'all') {
          await PMSBroadcastService.sendToAll(payload)
        } else if (this.selected === 'morning_brief') {
          await PMSBroadcastService.sendDailyBrief(payload)
        } else if (this.selected === 'role') {
          await PMSBroadcastService.sendToGroup(payload)
        } else if (this.selected === 'name') {
          await PMSBroadcastService.sendToTarget(payload)
        }

        this.setSnackbar({
          value: true,
          message: 'Messages are sent.',
          type: 'success'
        })
      } catch (error) {
        this.setSnackbar({
          value: true,
          message: `[SEND LINE ERROR]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.setLoading({ active: false })
      }
    }
  }
}
</script>

<style scoped>
.pms-broadcast-container {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 2px 12px;
}
.select-target {
  width: 80%;
  margin-top: 30px;
  align-self: center;
}
.pms-broadcast-select {
  width: 30%;
  min-width: 250px;
}
.pms-broadcast-message-section {
  width: 80%;
  margin-top: 30px;
  align-self: center;
}
.pms-broadcast-message-section > .send-message-button {
  margin-left: 50%;
  transform: translateX(-50%);
}
</style>
