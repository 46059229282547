<template>
  <v-data-table
    v-model="employeeSelected"
    :items="items"
    item-key="id"
    show-select
    :options.sync="paginate"
    :server-items-length="paginate.total"
    :headers="headers"
    :loading="loading"
    :footer-props="dataTableFooter"
    loading-text="กรุณารอสักครู่...">
    <template v-slot:[`item.tel`]="{ item }">
      <div>
        {{ item.tel }}
      </div>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      {{ fullNameCompute(item) }}
    </template>
  </v-data-table>
</template>

<script>

export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      employeeSelected: [],
      dataTableFooter: {
        itemsPerPageText: 'จำนวนแสดงต่อหน้า',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true
      },
      headers: [
        {
          text: 'ชื่อ-นามสกุล',
          align: 'center',
          value: 'name'
        },
        {
          text: 'Email',
          align: 'center',
          value: 'email'
        },
        {
          text: 'เบอร์โทรศัพท์',
          align: 'center',
          value: 'tel'
        }
      ]
    }
  },
  computed: {
    paginate: {
      get () {
        return this.value
      },
      set (_value) {
        const value = { ..._value, page: _value.page || 1 }
        this.$emit('input', value)
      }
    }
  },
  watch: {
    employeeSelected: {
      handler (values) {
        this.returnEmployees(values)
      },
      deep: true
    }
  },
  methods: {
    fullNameCompute (employee) {
      return `${employee.firstName} ${employee.lastName}`
    },
    returnEmployees (values) {
      this.$emit('return-employees', values)
    }
  }
}
</script>
