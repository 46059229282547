<template>
  <v-row
    justify="space-between"
    class="head-search ma-0">
    <v-col cols="10" />
    <v-col
      cols="2"
      class="d-flex align-center date-picker-col">
      <v-text-field
        v-model="innerFilter.search"
        dense
        label="ค้นหา"
        hide-details
        outlined />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    filter: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      searching: null
    }
  },
  computed: {
    innerFilter: {
      get () {
        return this.filter
      },
      set () {
        // do nothing
      }
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'innerFilter.search': function () {
      this.searchControl()
    }
  },
  methods: {
    bindValue () {
      this.$emit('filter-clicked', this.innerFilter)
    },
    searchControl () {
      clearTimeout(this.searching)
      this.searching = setTimeout(() => {
        this.bindValue()
      }, 1000)
    }
  }
}
</script>

<style scoped>
.filter-btn {
  margin-top: 2px;
}
</style>
