import { getAuthToken } from '@/assets/js/Authentication'
import HttpRequest from './HttpRequest'

class PMSBroadcastProvider extends HttpRequest {
  sendToAll (payload) {
    this.setHeader(getAuthToken())
    return this.post('/pms-broadcast/all', payload)
  }

  sendToGroup (payload) {
    this.setHeader(getAuthToken())
    return this.post('/pms-broadcast/group', payload)
  }

  sendDailyBrief (payload) {
    this.setHeader(getAuthToken())
    return this.post('/pms-broadcast/daily-brief', payload)
  }

  sendToTarget (payload) {
    this.setHeader(getAuthToken())
    return this.post('/pms-broadcast/targets', payload)
  }
}

export default PMSBroadcastProvider
