<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-top-transition">
    <v-card>
      <v-toolbar
        dark
        color="primary">
        <v-toolbar-title>พนักงาน</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            icon
            dark
            @click="closeModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-container
        class="products-list-page"
        fluid>
        <employees-header
          :filter="filter"
          @filter-clicked="filterClicked($event)" />
        <v-row class="products-row">
          <v-col cols="12">
            <employees-list
              v-model="query"
              :items="employees"
              :loading="fetchingEmployees"
              @return-employees="recieveEmployees($event)" />
          </v-col>
          <v-col
            cols="12"
            class="d-flex justify-center">
            <div>
              <v-btn
                color="secondary"
                @click="submit(false)">
                เลือก
              </v-btn>
              <v-btn
                color="secondary"
                class="ml-4"
                @click="submit(true)">
                เลือกทั้งหมด
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import EmployeeProvider from '@/resources/EmployeeProvider'
import EmployeesHeader from './EmployeesHeader.vue'
import EmployeesList from './EmployeesList.vue'

const EmployeeService = new EmployeeProvider()

export default {
  components: {
    EmployeesHeader,
    EmployeesList
  },
  props: {
    dialog: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      fetchingEmployees: false,
      employees: [],
      employeeSelected: [],
      filter: {
        search: ''
      },
      query: {
        page: 1,
        itemsPerPage: 10,
        total: 0,
        pages: 1,
        sortBy: ['createdAt'],
        sortDesc: [true]
      }
    }
  },
  watch: {
    query: {
      handler () {
        this.getEmployees()
      },
      deep: true
    }
  },
  async mounted () {
    this.getEmployees()
  },
  methods: {
    ...mapActions({
      setSnackbar: 'Components/setSnackbar',
      setModal: 'Components/setModal',
      setLoading: 'Components/setLoading',
      setMember: 'Member/setMember'
    }),
    async getEmployees () {
      try {
        this.fetchingEmployees = true

        const tmpQuery = {
          ...this.query,
          sortBy: this.query.sortBy[0]
        }

        const { data } = await EmployeeService.getEmployees({ ...tmpQuery, ...this.filter })

        this.employees = data.results
        this.query.total = data.total
        this.query.pages = data.pages
      } catch (error) {
        console.error('error', error)
        this.setSnackbar({
          value: true,
          message: `[GETTING EMPLOYEE INFO]: ${error.message}`,
          type: 'error'
        })
      } finally {
        this.fetchingEmployees = false
      }
    },
    filterClicked (filter) {
      this.filter = filter
      this.getEmployees()
    },
    closeModal () {
      this.$emit('close-modal')
    },
    recieveEmployees (employees) {
      this.employeeSelected = employees
    },
    submit (all) {
      const employees = all ? this.employees : this.employeeSelected
      this.$emit('input', employees)
    }
  }
}
</script>
